<template>
  <div class="contact-section" id="contact-section">
    <h1>Contact Us</h1>
    <form @submit.prevent="submitForm" method="POST">
      <label for="name">
        Your Name:
        <input
          v-model="form.name"
          type="text"
          name="name"
          placeholder="Name"
          required
        />
      </label>
      <label for="telephone">
        Your Phone Number:
        <input
          v-model="form.tel"
          type="tel"
          name="telephone"
          placeholder="Phone Number"
          required
        />
      </label>
      <label for="email">
        Your Email:
        <input
          v-model="form.email"
          type="email"
          name="email"
          placeholder="Email"
          required
        />
      </label>
      <label for="services">
        Select Services
        <select v-model="form.services" name="services" id="services" multiple>
          <option value="Commercial Cleaning">Commercial Cleaning</option>
          <option value="Office Cleaning">Office Cleaning</option>
          <option value="Carpet Cleaning">Carpet Cleaning</option>
          <option value="Post-Construction">Post-Construction</option>
          <option value="Power Washer">Power Washer</option>
          <option value="Move In/Out Cleaning">Move In/Out Cleaning</option>
        </select>
      </label>
      <label for="zipcode">
        ZipCode:
        <input
          v-model="form.zip"
          type="text"
          name="zip"
          placeholder="Zip Code"
          required
        />
      </label>
      <label>
        Your Message:
        <textarea
          v-model="form.message"
          name="message"
          id=""
          cols="30"
          rows="10"
          required
        ></textarea>
      </label>
      <button type="submit">Send</button>
    </form>

    <p :class="{ 'success-message': isSuccess, 'error-message': !isSuccess }">
      {{ responseMessage }}
    </p>
  </div>
</template>

<script defer>
export default {
  name: "contact",
  data() {
    return {
      form: {
        name: "",
        tel: "",
        email: "",
        zip: "",
        message: "",
        services: [],
      },
      responseMessage: "",
      isSuccess: false,
    };
  },
  methods: {
    isWashigtonZip(zip) {
      const zipNum = parseInt(zip, 10);
      return zipNum >= 98001 && zipNum <= 99403;
    },
    async submitForm() {
      if (!this.isWashigtonZip(this.form.zip)) {
        this.responseMessage =
          "Only residents of Washington state can submit this form.";
        return;
      }

      const formData = new FormData();
      formData.append("name", this.form.name);
      formData.append("tel", this.form.tel);
      formData.append("email", this.form.email);
      formData.append("zipCode", this.form.zip);
      formData.append("services", this.form.services.join(", "));
      formData.append("message", this.form.message);

      try {
        const response = await fetch("https://formspree.io/f/mkgwapvk", {
          method: "POST",
          body: formData,
          headers: {
            Accept: "application/json",
          },
        });

        if (response.ok) {
          this.responseMessage =
            "¡Thank you for your message! We will get in touch with you soon.";
          this.isSuccess = true;
          this.resetForm();
        } else {
          this.responseMessage =
            "Oops! Something went wrong. Please try again.";
          this.isSuccess = true;
        }
      } catch (error) {
        this.responseMessage =
          "There was a problem with you submission. Please try again.";
        this.isSuccess = true;
      }
    },

    resetForm() {
      this.form.name = "";
      this.form.tel = "";
      this.form.email = "";
      this.form.message = "";
    },
  },
  //"https://formspree.io/f/mkgwapvk"
};
</script>

<style lang="scss" scoped>
.contact-section {
  width: auto;
  height: auto;
  padding: 20px;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;

  h1 {
    margin: 20px;
  }

  .success-message {
    color: green;
  }

  .error-message {
    color: red;
  }

  form {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: -10px -10px 0 0 rgba($color: #eeb84f, $alpha: 1);
    border-radius: 5px;
    border: solid #eeb84f 2px;
    padding: 20px;
    margin: 20px;
    justify-content: center;
    align-items: center;

    button {
      background-color: #ffffff;
      box-shadow: -5px -5px 0 0 rgba($color: #eeb84f, $alpha: 1);
      border-radius: 5px;
      border: solid #eeb84f 2px;
      color: #000000;
      width: 100px;
      height: 30px;
      margin: 15px;
    }
    button:hover {
      background-color: #eeb84f;
      box-shadow: -5px -5px 0 0 rgba($color: #959595, $alpha: 0.3);
    }

    label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #000000;

      input,
      select {
        margin: 10px;
        border: solid #eeb84f 2px;
        border-radius: 5px;
        height: 30px;
        width: 100%;
        text-align: center;
      }

      textarea {
        margin: 10px;
        border: solid #eeb84f 2px;
        border-radius: 5px;
        height: 100px;
        width: 100%;
      }
    }
  }
}
</style>
