import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createHead } from "@vueuse/head";

// Importar styles Globales
import "./assets/styles/Normalize.css";

const head = createHead();
const app = createApp(App);

app.use(store);
app.use(router);
app.use(head);

app.mount("#app");
