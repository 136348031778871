<template>
  <div class="division"></div>
</template>

<script>
export default {
  name: "division",
};
</script>

<style lang="scss" scoped>
.division {
  width: 100%;
  height: 2px;
  border: solid #eeb84f 1px;
}
</style>
