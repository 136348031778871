<template>
  <div class="footer">
    <p>&copy; 2024 Imperial Solution. All rights reserved.</p>
    <div class="social-media">
      <h3>Social Media</h3>
      <div class="links-to-social-media">
        <a
          href="https://www.facebook.com/profile.php?id=61564981363913&mibextid=LQQJ4d"
          target="_blank"
          aria-label="Facebook"
        >
          <i class="fab fa-facebook"></i>
        </a>
        <a
          href="https://www.tiktok.com/@imperial.solution?_t=8pyOPpLT8BD&_r=1"
          target="_blank"
          aria-label="Tiktok"
        >
          <i class="fab fa-tiktok"></i>
        </a>
        <a
          href="https://www.linkedin.com/in/juan-santos-vargas-994349202?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
          target="_blank"
          aria-label="Linkedin"
        >
          <i class="fab fa-linkedin"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script defer>
export default {
  name: "footer",
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  padding: 20px;
  flex-direction: row;
  width: 100%;
  height: auto;
  background-color: #000000;
  color: #ffffff;
  justify-content: center;
  align-items: center;

  .social-media {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;

    h3 {
      width: auto;
      height: 20%;
      padding-bottom: 15px;
    }

    .links-to-social-media {
      width: auto;
      height: 80%;
      justify-content: center;
      align-items: start;
      margin: 0;
      padding: 0;

      a {
        margin-right: 10px;

        i {
          font-size: 30px;
          color: #eeb84f;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .footer {
    display: flex;
    padding: 20px;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-color: #000000;
    color: #ffffff;
    justify-content: center;
    align-items: center;

    .social-media {
      width: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h3 {
        width: auto;
        height: 20%;
      }

      .links-to-social-media {
        width: auto;
        height: 80%;
        justify-content: center;
        align-items: start;
        display: flex;
        flex-direction: row;
        margin: 15px;
        padding: 0;

        a {
          margin-right: 10px;

          i {
            font-size: 30px;
            color: #eeb84f;
          }
        }
      }
    }
  }
}
</style>
