<template>
  <div class="values-container">
    <h2>Why we are Imperial Solution</h2>
    <p>
      At <b>Imperial Solution</b>, we are defined by the values that guide our
      work and enable us to deliver exceptional cleaning services.
      <b>Professionalism</b> is the cornerstone of our team, ensuring every task
      is completed with precision, efficiency, and dedication. Our commitment to
      <b>honesty</b> sets us apart; we believe in transparency and building
      relationships based on trust with our clients, offering reliable service
      without hidden fees or surprises.
    </p>

    <p>
      We prioritize <b>attention to detail</b>, knowing that every small element
      matters to ensure spaces are left perfectly clean and presentable. We take
      full <b>responsibility</b> for every project we undertake, ensuring we
      meet and exceed our clients’ expectations.
    </p>

    <p>
      Lastly, <b>family</b> is at the heart of Imperial Solution. This value
      drives our commitment to treating our clients as part of our family, with
      the care, dedication, and respect they deserve. These principles are what
      make us Imperial Solution: a team dedicated to creating clean and healthy
      environments, where quality and trust are always at the forefront.
    </p>
    <div class="values">
      <div class="value">
        <h3>Responsibility</h3>
        <picture>
          <source
            srcset="../assets/WebP-images/Responsibilidad.webp"
            type="image/webp"
          />
          <img
            src="../assets/png-images/Responsibilidad.png"
            alt="Imperial solution Responsibility"
            width="100px"
            height="100px"
          />
        </picture>
      </div>
      <div class="value">
        <h3>Honesty</h3>
        <picture>
          <source
            srcset="../assets/WebP-images/Honestidad.webp"
            type="image/webp"
          />
          <img
            src="../assets/png-images/Honestidad.png"
            alt="Imperial solution Honesty"
            width="100px"
            height="100px"
          />
        </picture>
      </div>
      <div class="value">
        <h3>Attention to detail</h3>
        <picture>
          <source
            srcset="../assets/WebP-images/Atencion-Detalle.webp"
            type="image/webp"
          />
          <img
            src="../assets/png-images/Atencion-Detalle.png"
            alt="Imperial solution Attention to detail"
            width="100px"
            height="100px"
          />
        </picture>
      </div>
      <div class="value">
        <h3>Professionalism</h3>
        <picture>
          <source
            srcset="../assets/WebP-images/Profesionalismo.webp"
            type="image/webp"
          />
          <img
            src="../assets/png-images/Profesionalismo.png"
            alt="Imperial solution Professionalism"
            width="100px"
            height="100px"
          />
        </picture>
      </div>
      <div class="value">
        <h3>Family</h3>
        <picture>
          <source
            srcset="../assets/WebP-images/Familia.webp"
            type="image/webp"
          />
          <img
            src="../assets/png-images/Familia.png"
            alt="Imperial solution family"
            width="100px"
            height="100px"
          />
        </picture>
      </div>
    </div>
  </div>
</template>

<script defer>
export default {
  name: "values",
};
</script>

<style lang="scss" scoped>
.values-container {
  background-color: #000000;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 20px;
  flex-direction: column;
  color: #ffffff;

  h2 {
    padding: 25px;
  }

  p {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }

  .values {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;

    .value {
      color: #000000;
      box-shadow: -10px -10px 0 0 rgba($color: #eeb84f, $alpha: 1);
      background-color: #ffffff;
      border-radius: 5px;
      padding: 20px;
      display: flex;
      width: 200px;
      height: 200px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 15px;
    }
  }
}
</style>
