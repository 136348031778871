<template>
  <Cabecera></Cabecera>
  <Division></Division>
  <div class="contact-container">
    <Contact></Contact>
    <div class="map">
      <h2>Location</h2>
      <iframe
        src="https://www.google.com/maps/d/u/0/embed?mid=1_rn3L_-NQkQgP5amdPsgV9B6a-qHSmw&ehbc=2E312F&noprof=1&output=embed"
        width="400px"
        height="300px"
        controls="0"
        style="border: 2px solid #eeb84f"
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  </div>
  <Division></Division>
  <Base></Base>
</template>

<script>
import Cabecera from "@/components/Cabecera.vue";
import Base from "@/components/Base.vue";
import Contact from "@/components/Contact.vue";
import Division from "@/components/Division.vue";
import { useHead } from "@vueuse/head";

export default {
  name: "services",
  components: {
    Cabecera,
    Base,
    Contact,
    Division,
  },
  setup() {
    useHead({
      title:
        "Contact Us | Imperial Solution - Commercial and Carpet Cleaning Services",
      meta: [
        {
          name: "description",
          content:
            "Contact Imperial Solution for professional commercial and carpet cleaning services in Bellevue, Seattle, and surrounding areas. Get in touch with us for a free quote or more information.",
        },
        {
          name: "keywords",
          content:
            "Contact Imperial Solution, commercial cleaning, carpet cleaning, Bellevue, Seattle, free quote, office cleaning, professional cleaning services",
        },
        {
          property: "og:title",
          content:
            "Contact Us | Imperial Solution - Commercial and Carpet Cleaning Services",
        },
        {
          property: "og:description",
          content:
            "Reach out to Imperial Solution for top-tier commercial and carpet cleaning services in Bellevue, Seattle, and surrounding areas. Get a free quote or ask for more information!",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:url",
          content: "https://imperial-solution.com/contact",
        },
        {
          property: "og:image",
          content:
            "https://imperial-solution.com/img/imperial-solution-logo.png", // Coloca la URL de la imagen del logo o una imagen representativa
        },
        {
          name: "robots",
          content: "index, follow",
        },
      ],
      link: [
        { rel: "canonical", href: "https://imperial-solution.com/contact" },
      ],
    });
  },
};
</script>

<style lang="scss" scoped>
.contact-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  background-color: #000000;

  .map {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;

    h2 {
      color: #ffffff;
      margin: 20px;
    }

    iframe {
      width: 400px;
      height: 300px;
      box-shadow: -10px -10px 0 0 rgba($color: #eeb84f, $alpha: 1);
      border-radius: 5px;
    }
  }
}
@media only screen and (max-width: 768px) {
  .contact-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    background-color: #000000;
    padding-bottom: 20px;

    .map {
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;

      h2 {
        color: #ffffff;
        margin: 20px;
      }

      iframe {
        width: 400px;
        height: 300px;
        box-shadow: -10px -10px 0 0 rgba($color: #eeb84f, $alpha: 1);
        border-radius: 5px;
      }
    }
  }
}
</style>
