<template>
  <header>
    <div class="cabecera">
      <div @click="toggleMenu" class="menu-icon">
        <i v-if="!isMenuOpen" class="fas fa-bars"></i>
        <i v-else class="fa-solid fa-x"></i>
      </div>
      <div class="imagen-logo">
        <picture>
          <source
            srcset="../assets/WebP-images/imperial-solution-logo.webp"
            type="image/webp"
          />
          <img
            src="../assets/png-images/imperial-solution-logo.png"
            alt="logo imperial solution"
            width="255px"
            height="90px"
            loading="lazy"
          />
        </picture>
      </div>
      <div class="nav-links">
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/services">Services</a>
          </li>
          <li>
            <a href="/contact">Contact</a>
          </li>
        </ul>
      </div>
    </div>
    <transition name="slide" appear>
      <nav v-if="isMenuOpen" class="hamburger-menu">
        <ul>
          <li><a href="/">Home</a></li>
          <li><a href="/services">Services</a></li>
          <li><a href="/contact">Contact</a></li>
        </ul>
      </nav>
    </transition>
  </header>
</template>

<script defer>
export default {
  // eslint-disable-next-line
  name: "Cabecera",
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  padding-bottom: 20px;
  background-color: #000000;
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

@media only screen and (min-width: 769px) {
  .hamburger-menu {
    display: none;
  }
  .cabecera {
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    padding-top: 20px;
    flex-direction: column;

    .menu-icon {
      display: none;
      i {
        font-size: 30px;
        color: #eeb84f;
      }
    }

    .imagen-logo {
      img {
        max-width: auto;
        max-height: 90px;
      }
    }

    .nav-links {
      padding-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-content: center;

      ul {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        li {
          margin-left: 15px;
          width: 100px;
          height: 30px;
          background-color: #ffffff;
          box-shadow: -10px -10px 0 0 rgba($color: #eeb84f, $alpha: 1);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          a {
            color: #000000;
            text-decoration: none;
            font-size: large;
            font-weight: bold;
          }
        }

        li:hover {
          color: #000000;
          background-color: #eeb84f;
          box-shadow: -10px -10px 0 0 rgba($color: #ffffff, $alpha: 1);
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  header {
    display: flex;
    flex-direction: column;
    background-color: #000000;
    justify-content: start;
    align-items: start;
    .cabecera {
      background-color: #000000;
      display: flex;
      justify-content: start;
      align-items: center;
      height: auto;
      padding-top: 20px;
      flex-direction: row;
      .menu-icon {
        margin: 15px;
        width: 10%;
        height: auto;
        i {
          display: flex;
          font-size: 30px;
          color: #eeb84f;
        }
      }
      .imagen-logo {
        display: flex;
        justify-content: start;
        align-items: center;
        img {
          max-width: auto;
          max-height: 90px;
        }
      }
      .nav-links {
        display: none;
      }
    }
    nav {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: auto;
      padding-top: 20px;
      ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        li {
          margin: 25px;
          width: 200px;
          height: 30px;
          background-color: #ffffff;
          box-shadow: -10px -10px 0 0 rgba($color: #eeb84f, $alpha: 1);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          a {
            color: #000000;
            text-decoration: none;
            font-size: large;
            font-weight: bold;
          }
        }

        li:hover {
          color: #000000;
          background-color: #eeb84f;
          box-shadow: -10px -10px 0 0 rgba($color: #ffffff, $alpha: 1);
        }
      }
    }
  }
}
</style>
