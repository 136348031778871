<template>
  <div class="service">
    <div class="service-name">
      <h2>{{ service.name }}</h2>
      <i @click="toggle" v-if="!isToggle" class="fa-solid fa-arrow-right"></i>
      <i @click="toggle" v-else class="fa-solid fa-arrow-up"></i>
    </div>
    <div v-if="isToggle" class="service-description">
      <p>{{ service.description }}</p>
      <ul>
        <li v-for="task in service.tasks" :key="task.task_id">
          <i class="fa-solid fa-soap"></i>
          <span>{{ task.description }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script defer>
export default {
  name: "Services",
  data() {
    return {
      isToggle: false,
    };
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  methods: {
    toggle() {
      this.isToggle = !this.isToggle;
    },
  },
};
</script>

<style lang="scss" scoped>
.service {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 20px;

  .service-name {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 20px;
    justify-content: space-around;

    h2 {
      width: 20%;
      color: #eeb84f;
    }
    i {
      cursor: pointer;
      width: auto;
      font-size: 36px;
      color: #eeb84f;
      transition: opacity 0.5s ease, transform 0.5s ease;
      opacity: 1;
    }

    i-enter-active,
    i-leave-active {
      transition: opacity 0.5s ease, transform 0.5s ease;
    }
    i-enter,
    i-leave-to {
      opacity: 0;
      transform: scale(0.9);
    }
  }

  .service-description {
    margin: 15px;
    display: flex;
    flex-direction: column;

    p {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 18px;
    }

    ul {
      margin: 20px;

      li {
        margin-top: 15px;
        display: flex;
        flex-direction: row;

        i {
          margin-right: 15px;
          color: #eeb84f;
          font-size: 20px;
        }
        span {
          width: auto;
          color: #ffffff;
          font-size: 18px;
        }
      }
    }
  }
}
</style>
