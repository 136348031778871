import { createRouter, createWebHistory } from "vue-router";
import Contact from "../views/Contact.vue";
import Services from "../views/Services.vue";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "",
    name: "home",
    component: HomeView,
    meta: {
      title: "Home Page",
      requiresAuth: false,
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },
  {
    path: "/services",
    name: "services",
    component: Services,
    meta: {
      title: "Services Page",
      requiresAuth: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
