<template>
  <Cabecera></Cabecera>
  <Division></Division>
  <div v-if="services.length" class="services">
    <h1>Services</h1>
    <Services
      v-for="service in services"
      :key="service.id"
      :service="service"
    ></Services>
  </div>
  <div v-else>
    <span>Loading services....</span>
  </div>
  <Division></Division>
  <Base></Base>
</template>

<script defer>
import Cabecera from "@/components/Cabecera.vue";
import Base from "@/components/Base.vue";
import Division from "@/components/Division.vue";
import Services from "@/components/Services.vue";
import { useHead } from "@vueuse/head";

export default {
  name: "services",
  components: {
    Cabecera,
    Base,
    Division,
    Services,
  },
  data() {
    return {
      services: [],
    };
  },
  mounted() {
    fetch("/json/services-data.json")
      .then((response) => response.json())
      .then((data) => {
        this.services = data;
      })
      .catch((error) => {
        console.error("Error fetching JSON:", error);
      });
  },
  setup() {
    useHead({
      title: "Servicios - Imperial Solution | Services - Imperial Solution",
      meta: [
        // Descripción en español
        {
          name: "description",
          content:
            "Imperial Solution ofrece una variedad de servicios de limpieza, incluyendo limpieza comercial, limpieza de alfombras y servicios de conserjería en Bellevue y Seattle.",
        },
        // Descripción en inglés
        {
          name: "description",
          content:
            "Imperial Solution offers a variety of cleaning services, including commercial cleaning, carpet cleaning, and janitorial services in Bellevue and Seattle.",
        },
        // Keywords en español e inglés
        {
          name: "keywords",
          content:
            "servicios de limpieza, limpieza de alfombras, servicios de conserjería, limpieza en Bellevue, limpieza en Seattle, limpieza comercial, limpieza de oficinas, limpieza profunda, limpieza de apartamentos, limpieza profesional, cuidado de suelos, limpieza de tapicería, limpieza ecológica, servicios de limpieza locales, Imperial Solution, cleaning services, carpet cleaning, janitorial services, Bellevue cleaning, Seattle cleaning, commercial cleaning, office cleaning, deep cleaning, apartment cleaning, professional cleaning, floor care, upholstery cleaning, green cleaning, eco-friendly cleaning, local cleaning services, Imperial Solution",
        },
        // Robots
        {
          name: "robots",
          content: "index, follow",
        },
        // Open Graph (OG) en español
        {
          property: "og:title",
          content:
            "Servicios de limpieza comercial y de alfombras - Imperial Solution",
        },
        {
          property: "og:description",
          content:
            "Mejores servicios de limpieza comercial y de conserjería en Bellevue, Seattle y áreas circundantes.",
        },
        {
          property: "og:url",
          content: "https://imperial-solution.com/services",
        },
        {
          property: "og:image",
          content: "https://imperial-solution.com/images/services-banner.jpg",
        },
        // Open Graph (OG) en inglés
        {
          property: "og:title",
          content:
            "Commercial and Carpet Cleaning Services - Imperial Solution",
        },
        {
          property: "og:description",
          content:
            "Best commercial cleaning and janitorial services in Bellevue, Seattle, and surrounding areas.",
        },
        {
          property: "og:url",
          content: "https://imperial-solution.com/services",
        },
        {
          property: "og:image",
          content: "https://imperial-solution.com/images/services-banner.jpg",
        },
      ],
    });
  },
};
</script>

<style lang="scss" scoped>
.services {
  background-color: #000000;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: auto;

  h1 {
    margin: 20px;
  }
}
</style>
